import React from "react";

type CheckboxProps = {
  title?: string;
  onChange?: any;
  onBlur?: any;
  value?: string;
  checked?: any;
  errMsg?: string;
  margin?: string;
  formTitleFontSize?: string;
  formTitleFontColor?: string;
  isDisabled?: boolean;
  required?: boolean;
  label?: string;
  size?: string;
  paddingLeft?: string;
  marginBottom?: string;
};

export const CheckBox = ({
  title,
  onChange,
  onBlur,
  value,
  checked,
  errMsg,
  margin,
  formTitleFontSize,
  formTitleFontColor,
  isDisabled,
  required = false,
  label,
  size,
  paddingLeft,
  marginBottom
}: CheckboxProps) => {
  return (
    <React.Fragment>
      <div className="form-input">
        <div className="input-checkbox-wrapper">
          <label className="input-checkmark-wrapper">
            {label}
            <input
              type="checkbox"
              className={"input-checkbox" + (errMsg ? " input--error" : "")}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              checked={checked}
              disabled={isDisabled}
              required={required}
            />
            <span className="input-checkmark"></span>
          </label>
          <div className="input-title">{title}</div>
        </div>
        <div className="error-msg">{errMsg}</div>
      </div>
      <style jsx>{`
        .error-msg {
          color: var(--color-red);
          text-align: right;
          font-size: 12px;
        }
        .form-input {
          margin: ${margin ? margin : ""};
        }
        .input-checkbox-wrapper {
          display: flex;
          justify-content: flex-start;
          margin: 10px 0 0;
          align-items: center;
        }
        .input-checkmark-wrapper {
          display: block;
          position: relative;
          padding-left: ${paddingLeft ? paddingLeft : "35px"};
          margin-bottom: ${marginBottom ? marginBottom : "12px"};
          font-size: ${size === "small" ? "12px" : "15px"};
          cursor: pointer;
        }
        .input-checkmark {
          position: absolute;
          border: 2px solid ${isDisabled ? "gray" : "#e11931"};
          width: ${size === "small" ? "15px" : "24px"};
          height: ${size === "small" ? "15px" : "24px"};
          left: 0;
          top: 0;
          margin-top: ${size === "small" ? "4px" : ""};
          background-color: ${isDisabled ? "var(--color-white-soft)" : "white"};
        }
        .input-checkbox {
          position: absolute;
          left: 0;
          top: 0;
          width: 24px;
          height: 24px;
          opacity: 0;
          z-index: 1;
          cursor: pointer;
        }
        .input-checkbox:checked ~ .input-checkmark {
          background-color: #e11931;
          text-align: center;
        }
        .input-checkbox:checked ~ .input-checkmark::after {
          content: "${"\\2713"}";
          color: white;
        }
        .input--error {
          border: 1px solid var(--color-red);
        }
        .input-title {
          font-size: ${formTitleFontSize ? formTitleFontSize : "15px"};
          color: ${formTitleFontColor
            ? formTitleFontColor
            : "var(--color-gray)"};
          line-height: 19px;
          margin-left: 40px;
          text-align: justify;
        }
        @media only screen and (max-width: 600px) {
          .input-title {
            font-size: 12px;
          }
          .input-checkbox {
            margin: 1px 16px 0 0;
            height: 15px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};
